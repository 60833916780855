
import { defineComponent } from "vue";
import MenuItems from "@/components/menu/MenuItems.vue";
// import { Menu } from "@/models/Menu";

export default defineComponent({
  name: "MenuGroup",
  components: { MenuItems },
  props: {
    tab: Object,
  },
});
