
import { defineComponent } from "vue";
import json from "@/settings.json";
import { DEFAULT_NAV } from "@/constants";
import { Menu } from "@/models/Menu";
import MenuGroup from "@/components/menu/MenuGroup.vue";

export default defineComponent({
  name: "JsonView",
  components: { MenuGroup },
  setup() {
    console.log("setup executed");
  },
  created() {
    /* settings.json is alway structured in two levels (category and groupO). 
    1) Category to determine if it is a system or an application setting.
    2) Group to summarise settings that belong together. Note: These are also set together.

    To archieve more flexibility, the display structure does not depend on these 2 levels.
    Each setting has navigation tags. The tags are used to set the navigation structure for this setting. 
    Likewise multiple output places are allowed.
    
    */
    // Category --- System or Application setting
    for (const [namespace, categoryObj] of Object.entries(json)) {
      if ("object" === typeof categoryObj) {
        // Group in settings.json
        for (const [groupName, groupObj] of Object.entries(categoryObj)) {
          if ("object" === typeof groupObj) {
            for (const [settingName, settingObj] of Object.entries(groupObj)) {
              // Setting in settings.json
              if ("object" === typeof settingObj) {
                if (!(settingObj.path && settingObj.path.length > 0)) {
                  // Setting has no navigation tags
                  settingObj.path = DEFAULT_NAV;
                }
                // combine all display options in one object
                const setting = {
                  namespace,
                  groupName,
                  settingName,
                  ...settingObj,
                };
                // build path with menuPointer
                settingObj.path.forEach((path: string) => {
                  let menuPointer: Menu = this.menu;
                  for (const subpath of path) {
                    // create subpath property
                    subpath in menuPointer || (menuPointer[subpath] = {});
                    // set current subpath
                    menuPointer = menuPointer[subpath];
                  }
                  // create items property
                  "items" in menuPointer || (menuPointer.items = []);

                  // push setting to current menu child
                  menuPointer.items!.push(setting);
                });
              }
            }
          }
        }
      }
    }
  },
  data() {
    return {
      menu: {},
    };
  },
});
