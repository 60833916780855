
import { defineComponent } from "vue";
// import { Menu } from "@/models/Menu";

export default defineComponent({
  name: "MenuItems",
  props: {
    items: Object,
  },
});
